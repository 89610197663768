import {nextTick} from 'vue';
import EngineCommon from './engineCommon'
export default class EngineUtil {
    public dataObj:any;
    public proxy:any;
    public utils:any;

    constructor(dataObj:any,proxy:any,utils:any) {
        this.dataObj=dataObj;
        this.proxy=proxy;
        this.utils=utils;
    }

    //新增单据或者加载单据信息
    public async doAddOrLoad(id:string,type:string='card'):Promise<void>{
        this.dataObj.loading=true;
        if(EngineCommon.existMethod(this.dataObj,'addOrLoad')){
            await EngineCommon.execMethod(this.proxy,'addOrLoad')
        }else{
            let addOrLoad = this.utils.UtilPub.isNotEmpty(id)?'/load':'/add'; //根据id是否为空判断是该发送新增还是编辑请求
            let url = this.dataObj.otherParams.compParams.modelPath + addOrLoad;//构造模块的请求地址
            let params={};
            //即将向后台发送/add或者/load请求，如果业务模块定义有setParam方法，则调用业务模块的该方法进行参数准备
            if(EngineCommon.existMethod(this.dataObj,'setParam'))params=await EngineCommon.execMethod(this.proxy,'setParam')||{};
            let billModel=this.dataObj.otherParams.compParams.billModel;//从包含的组件中取出该组件要使用的单据类型，卡片则为空
            if('bill'==type){
                params=Object.assign({id: id,modelType:'bill',billModel:billModel}, params);
            }else{
                params=Object.assign({id: id,modelType:'card',isBuildPageFromBack:true}, this.dataObj.otherParams.compParams,params);
                this.dataObj.id=id;//把当前正在操作的卡片id存起来,bill不用，因为在submitData、billInfo中都有
            }
            //发送请求并得到请求结果
            let res = await this.utils.Api.postRequest({url: url, params: params});
            if(res.result){
                if('bill'==type){
                    //为单据的submitData对象和billInfo对象赋值。其中submitData对象指要提交到后台的参数；billInfo对象指单据要显示的部份信息
                    this.dataObj.submitData.id=id || res.data.id//单据id
                    this.dataObj.submitData.curTask=res.curTask;//当前流程步骤
                    this.dataObj.submitData.billModel=billModel;//单据类型
                    this.dataObj.submitData.defineId=res.defineId;//流程定义id
                    this.dataObj.submitData.submitTask='';//重置一下当前要提交的步骤
                    this.dataObj.submitData.stepData='';//重置一下当前要提交的描述信息
                    this.dataObj.billInfo.taskUsers=[];//重置一下步骤和步骤对应的审核人员
                    this.dataObj.billInfo.id=id;//单据id
                    this.dataObj.billInfo.billBottomInfo=res.billBottomInfo;//单据底部信息
                    if(res.billBottomInfo.billAttach)this.dataObj.billInfo.billBottomInfo.billAttach=JSON.parse(res.billBottomInfo.billAttach);//billAttach后台返回的是字符串
                    if('/load'==addOrLoad){//是编辑的情况下billBottomInfo下面肯定有billAttach属性，否则没有这个属性
                        this.dataObj.billInfo.billBottomInfo.billAttach.billAttachParams={id:id};
                        //把审批日志放入一个数组，在页面使用的时候直接遍历数组就可以了。
                        let logInfo=this.dataObj.billInfo.billBottomInfo.logInfo;
                        this.dataObj.logInfoArr.length=0;//先清除数组，否则审批日志数组会把之前的也合并在一起
                        Object.keys(logInfo).forEach(key=>this.dataObj.logInfoArr.push({timestamp:key,content:logInfo[key]}));
                        this.dataObj.otherParams.sign.signInfo.length=0;//先清空
                        for(let key of Object.keys(this.dataObj.billInfo.billBottomInfo.signInfo)){
                            this.dataObj.otherParams.sign.signInfo.push({taskText:key.split('##@##')[0],signs:this.dataObj.billInfo.billBottomInfo.signInfo[key]})
                        }
                    }
                    if(res.billStatus)this.dataObj.otherParams.billStatus=res.billStatus;
                    this.dataObj.utilInst.initFlowStep();
                    let taskUsers=res.taskUsers;//流程步骤和步骤审核人员
                    //[
                    //     审批步骤ID####@###审批步骤名称:[该步骤可以审批的人员数组集合],
                    //     ............
                    // ]
                    if(taskUsers){
                        for(let taskInfo in taskUsers){
                            let taskArr=taskInfo.split("####@###"); //流程步骤id和流程步骤名称
                            let task={taskId:taskArr[0],taskText:taskArr[1]};
                            let users=taskUsers[taskInfo];//该流程步骤对应的审核人员
                            //构建提交弹出框中的流程步骤和审批人，默认没有选中任何人，默认所有的选择人下拉select都禁用
                            this.dataObj.billInfo.taskUsers.push({task:task,users:users,selUser:'',disabled:true,chooseOperator:taskArr[2]});
                        }
                        //如果流程已经结束，那么后台就会返回一个空的taskUsers集合给前端，因此billInfo.taskUsers就不会放入任何值，所以这里需要判断一下
                        if(this.dataObj.billInfo.taskUsers.length>0){
                            //默认选中第一个审批步骤
                            this.dataObj.submitData.submitTask=this.dataObj.billInfo.taskUsers[0].task.taskId;
                            //默认选中的是第一个审批步骤，因此把审批步骤后面的候选人打开，让其可以选择,因为上面禁用了所有的下拉选择人select，
                            this.dataObj.billInfo.taskUsers[0].disabled=false;
                        }
                    }

                    //如果单据已经在流程中了，则业务模块应该不可编辑了，因此为业务模块加上一个不可编辑的属性，以供业务模块使用
                    if(this.dataObj.submitData.curTask!='makeBill'){
                        if(res.modifyBill)this.dataObj.modelCompRef.disabled=!res.modifyBill;
                        else this.dataObj.modelCompRef.disabled=true;
                    }else{
                        this.dataObj.modelCompRef.disabled=false;
                    }
                }
                EngineCommon.getModelCompRef(this.proxy).form = res.data;//为引擎包含的组件实例form表单赋值
                this.dataObj.buttons = res.buttons; //为Engine顶部的buttons赋值
                if(res.pageInfo){//如果后台返回了pageInfo
                    let pageInfo=JSON.parse(res.pageInfo);
                    if(pageInfo.formItems)EngineCommon.getCompParams(this.proxy).formItems=pageInfo.formItems;
                    if(pageInfo.detailInfos)EngineCommon.getCompParams(this.proxy).detailInfos=pageInfo.detailInfos;//如果后台有返回明细
                }
            }
            //如果包含的组件实例有beforeOpen方法，则将被调用
            await EngineCommon.execMethod(this.proxy,'beforeOpen',{res:res,addOrLoad:addOrLoad});
            //如果单据有明细，不管是新增还是编辑都要向后台发送查询明细的请求
            if (this.dataObj.otherParams.compParams.hasDetails && this.dataObj.otherParams.compParams.details){
                let details=this.dataObj.otherParams.compParams.details;
                for (const detailType of details) {
                    //如果模块自己有定义加载明细的方法，则用模块自己的
                    if(EngineCommon.existMethod(this.dataObj,'loadDetails')){//todo:有问题···········································
                        let detailParams=Object.assign({detailType:detailType,id:id,addOrLoad:addOrLoad}, params);
                        await EngineCommon.execMethod(this.proxy,'loadDetails',detailParams);
                    }else{
                        let detailParams=Object.assign({detailType:detailType,mainId:id,addOrLoad:addOrLoad}, params);
                        await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).reloadGrid(detailParams);
                    }
                }
            }
            if(EngineCommon.existMethod(this.dataObj,'afterOpened'))await EngineCommon.execMethod(this.proxy,'afterOpened',{res:res,addOrLoad:addOrLoad});
        }
        //关闭正在加载效果
        this.dataObj.loading=false;
    }
    //保存
    public async doSave(engineParams?:any,type?:string,dialogInst?:any,closeDialog:boolean=true):Promise<boolean>{
        //打开正在加载效果
        this.dataObj.loading=true;
        //如果卡片含有明细信息，需要把明细内容处理为一个json字符串赋值给业务模块中form的一个属性
        if (this.dataObj.otherParams.compParams.hasDetails) {
            if(EngineCommon.existMethod(this.dataObj,'setDetailsJson')){
                await EngineCommon.execMethod(this.proxy,'setDetailsJson');
            }else{
                let details=this.dataObj.otherParams.compParams.details;

                for (const detailType of details) {
                    let detailData=await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).getSaveDetailData();
                    if(detailData){
                        EngineCommon.getModelCompRef(this.proxy).form[detailType]=detailData;
                    }else{
                        this.dataObj.loading=false;//如果明细有验证不成功的情况，则得不到明细数据
                        return false;
                    }
                }
            }
        }
        //发出保存之前接口方法
        if(EngineCommon.existMethod(this.dataObj,'beforeSaveHandler')){
            if(!await EngineCommon.execMethod(this.proxy,'beforeSaveHandler')){
                this.dataObj.loading=false;//关闭遮罩层
                return false;
            }
        }
        //构造保存请求路径和保存form表单数据
        let url = this.dataObj.otherParams.compParams.modelPath + "/save";
        //卡片的只需要把组件的form传入后台就可以了，单据的还需要把单据类型传入后台，保存header的时候需要，这里把整个submitData传入后台，方便存取
        let params=Object.assign(EngineCommon.getModelCompRef(this.proxy).form, this.dataObj.submitData);

        try {
            //发起保存请求并得到请求结果
            let res = await this.utils.Api.postRequest({url: url, params: params});
            if(res.result){
                this.utils.Tools.success({message: res.msg});
                if (!closeDialog){
                    //保存成功之后，重新加载form表单
                    await this.doAddOrLoad(res.data.id,type);
                    //保存成功之后，如果卡片有明细，则重新加载明细
                    if (this.dataObj.otherParams.compParams.hasDetails){
                        let details=this.dataObj.otherParams.compParams.details;
                        for (const detailType of details) {
                            //如果模块自己有定义加载明细的方法，则用模块自己的
                            if(EngineCommon.existMethod(this.dataObj,'loadDetails')){
                                await EngineCommon.execMethod(this.proxy,'loadDetails',{detailType:detailType,id:res.data.id,addOrLoad:'/load'});
                            }else{
                                await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).reloadGrid({detailType:detailType,mainId:res.data.id});
                            }
                        }
                    }
                }
                //向卡片发出保存之后接口回调方法
                if(EngineCommon.existMethod(this.dataObj,'afterSaveHandler')){
                    if(!await EngineCommon.execMethod(this.proxy,'afterSaveHandler',{res:res})){
                        this.dataObj.loading=false;//关闭遮罩层
                        return false;
                    }
                }
                //保存完之后调用列表页的queryHandler，刷新的grid，因为关闭diaolog的时候，不会刷新列表
                //如果调用打开dialog的不是列表，则没有queryHandler，就不会调用了
                if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler(false);
                if(closeDialog)this.closeDialog(dialogInst);//如果是单据，当点击保存并提交的时候，不能关闭对话框，否则提交窗口就被关闭了
            }else{
                //向卡片发出保存之后接口回调方法
                if(EngineCommon.existMethod(this.dataObj,'afterSaveHandler'))
                    await EngineCommon.execMethod(this.proxy,'afterSaveHandler',{res:res})
                this.dataObj.loading=false;
                return false;
            }
        } catch (e) {//如果发起请求后台抛出异常，需要关闭遮罩层，比如保存校验失败抛出异常
            this.dataObj.loading=false;
            return false;
        }
        return true;
    }
    //删除
    public async doDelete(engineParams:any,dialogInst:any):Promise<void>{
        this.dataObj.loading=true;//打开正在加载效果
        let id=EngineCommon.getModelCompRef(this.proxy).form.id;
        if(EngineCommon.existMethod(this.dataObj,'beforeDeleteHandler')){
            if(!await EngineCommon.execMethod(this.proxy,'beforeDeleteHandler',{id:id})){
                this.dataObj.loading=false;//关闭遮罩层
                return;
            }
        }
        //构造提交请求路径
        let url = this.dataObj.otherParams.compParams.modelPath + '/delete';
        //发起提交请求并得到请求结果
        let res = await this.utils.Api.postRequest({url: url, params: {id:id}});
        //关闭正在加载效果
        this.dataObj.loading=false;
        if(res.result){
            this.utils.Tools.success({message: res.msg});
            if(EngineCommon.existMethod(this.dataObj,'afterDeleteHandler'))await EngineCommon.execMethod(this.proxy,'afterDeleteHandler',{id:id})
            //删除之后会关闭dialog，调用列表页的queryHandler，刷新的grid
            if (engineParams.ownerComp && engineParams.ownerComp.queryHandler) engineParams.ownerComp.queryHandler();
            this.closeDialog(dialogInst);
        }
    }
    //重置
    public async doReset(type:string):Promise<void>{
        //如果现在是编辑状态打开dialog，则重新加载一下得到最初状态
        let id=EngineCommon.getModelCompRef(this.proxy).form.id;
        if (this.utils.UtilPub.isNotEmpty(id)) {
            await this.doAddOrLoad(id,type);
        } else {  //调用el-form自身提供的重置方法重置表单
            EngineCommon.getModelCompRef(this.proxy).formRef.resetFields([]);
            if (this.dataObj.otherParams.compParams.hasDetails && this.dataObj.otherParams.compParams.details){
                let details=this.dataObj.otherParams.compParams.details;
                for (const detailType of details) {
                    await EngineCommon.getModelCompRef(this.proxy).refMap.get(detailType).clearDetailData();
                }
            }
        }
        this.utils.Tools.success();
    }
    //保存事件
    public async saveHandler(engineParams:any,type:string='card',dialogInst:any):Promise<void>{
        //如果卡片自身有保存方法，则调用卡片自身的保存方法
        if(EngineCommon.existMethod(this.dataObj,'saveHandler')){
            await EngineCommon.execMethod(this.proxy,'saveHandler');
        } else {
            //如果卡片的form表单合法则发起保存请求方法
            await EngineCommon.getModelCompRef(this.proxy).formRef.validate(async (valid:BufferSource) =>{
                if(valid){//如果验证通过
                    //如果单据保存需要提醒是否保存等字样，则需要弹出确认框,那么单据需要显示的在compParams里面传入saveConfirm:true，默认是不会弹出是否确认保存弹出框
                    if(this.dataObj.otherParams.compParams.saveConfirm){
                        let message=this.dataObj.otherParams.compParams.saveConfirmContent;
                        if(!message)message=this.proxy.$t('cardEngine.saveTip');
                        this.utils.Tools.configBox({
                            message:message,
                            sureFn:async ()=> await this.doSave(engineParams,type,dialogInst),
                            cancelFn:()=>this.dataObj.loading=false
                        });
                    }else{//直接保存，不需要确认弹出框
                        await this.doSave(engineParams,type,dialogInst)
                    }
                }else{
                    if(EngineCommon.existMethod(this.dataObj,'afterErrorValid'))
                    EngineCommon.getModelCompRef(this.proxy)['afterErrorValid'](this.proxy);
                }
            });
        }
    }
    //删除事件
    public async delHandler(engineParams:any,id:any,dialogInst:any):Promise<void>{
        if(this.utils.UtilPub.isNotEmpty(id)){
            this.utils.Tools.configBox({
                message:this.proxy.$t('cardEngine.deleteTip'),
                sureFn:async ()=>{
                    await this.doDelete(engineParams,dialogInst);
                }
            });
        }else{
            this.proxy.$message(this.proxy.$t('cardEngine.addToDeleteTip'));
        }
    }
    //重置事件
    public async resetHandler(type:string='card'):Promise<void>{
        //如果卡片自身有重置方法，则调用卡片自身的重置方法
        if (EngineCommon.getModelCompRef(this.proxy)['resetHandler'] && !this.dataObj.otherParams.compParams.isEnginePage) {
            EngineCommon.getModelCompRef(this.proxy)['resetHandler'](this.proxy);
        } else {
            await this.dataObj.engineUtil.doReset(type);
            if (EngineCommon.getModelCompRef(this.proxy)['afterResetHandler'] && !this.dataObj.otherParams.compParams.isEnginePage) {
                EngineCommon.getModelCompRef(this.proxy)['afterResetHandler'](this.proxy);
            }
        }
    }
    //关闭弹窗
    public closeDialog(dialogInst:any):void{
        dialogInst.dialogVisible=false;//交给elementui自己去关闭吧
    }
    //动画
    public async animation():Promise<void>{
        await nextTick(()=>{
            this.utils.AnimateUtil.dialogFormItemAnimate(this.utils);
        })
    }
}